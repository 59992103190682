import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: { name: 'Login' },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () =>
      import(/* webpackChunkName: "Admin" */ '../views/admin/Index.vue'),
    children: [
      {
        path: '/admin',
        redirect: { name: 'MerchantList' },
      },
      {
        path: '/merchant/list',
        name: 'MerchantList',
        meta: {
          shouldLogin: true,
          isList: true
        },
        component: () => import(/* webpackChunkName: "MerchantList" */ '../views/admin/merchant/MerchantList.vue')
      },
      {
        path: '/system/config',
        name: 'SystemConfig',
        meta: {
          shouldLogin: true,
          isList: true
        },
        component: () => import(/* webpackChunkName: "SystemConfig" */ '../views/admin/system/Config.vue')
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
